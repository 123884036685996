import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NewsletterGen from "../components/newslettergen"


export default function WhoTemplate() {
  return (
    <Layout breadcrumbs={["Events"]}>
          <div className="carousel-container">
      <div className="group-5-about-us-events" >
      <div className="connecting-the-blockchain-together" >Join Our Events</div>
      <p className="about-us-carousel">Whether in-person or online, we hope you’ll join us for an upcoming event.<br /><br />Our in-person events are top-tier networking opportunities, where you’ll learn from trailblazers in the blockchain industry.<br /><br />Our online events include workshops, webinars, and panels that can be watched from anywhere—either live, or on-demand.<br /><br />Be inspired. Learn what’s new. And connect with our vibrant community. Join us!</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701203905/events/thumbnail_header_Events_Page_s0bkmv.jpg" class="group-3" />
    </div>  
    <div className="container-noflex">
      <div className="group-events-header">
        <h1>Upcoming Events</h1>
      </div>
       <div className="group-events-hosted">
          <div className="who-we-are-new">
             <a href="/events/life-sciences-2025/" target="_blank" className="full-width"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337028/events/December_3_BBA-TABB_Boston_event_cf9eyu.png"  className="image-events" /></a>
             <p className="container-events-text-date">March 25, 2025</p>  			 
            <p className="container-events-text-left">Revolutionary Health: Powering the Next Frontier with Blockchain and AI</p> 
             <a href="/events/life-sciences-2025/" target="_blank" className="full-width"><button className="events-button"><b>Learn more</b></button></a>                          
          </div>                    
      </div>         
	    <div className="group-events-hosted-new">
          <div className="who-we-are-new">
               <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337031/events/meetup_logo_grn3og.png" className="image-events-logos" /></a>
             <p className="container-events-text">To join upcoming events, join our Meetup group, where all new events are posted.</p>       
             <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank" className="full-width"><button className="events-button"><b>See Upcoming Events</b></button></a>      
          </div>
          <div className="who-we-are-new">
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337029/events/Eventbrite_Logo_otkaub.png"  className="image-events-logos" /></a>             
              <p className="container-events-text">To register for a ticketed event, use our Eventbrite page.</p>  
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank" className="full-width"><button className="events-button"><b>Register for Ticketed Event</b></button></a>                                  
          </div>   		  
          <div className="who-we-are-new">
            <a href="https://www.youtube.com/channel/UCO0fWrhXoq_x2_GdnmahVdQ" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337026/events/youtube_logo_brmdjp.png"  className="image-events-logos" /></a>
             <p className="container-events-text">Most past online events are archived on our BBA YouTube channel.</p> 
             <a href="https://www.youtube.com/channel/UCO0fWrhXoq_x2_GdnmahVdQ" target="_blank" className="full-width"><button className="events-button"><b>View Previous Events</b></button></a>                          
          </div>                    
      </div>       
    </div>  
   
    <div className="container-noflex-events">
        <div className=" who-we-are-new-volunteer-cta-new">
          <div className=" who-we-are-new-volunteer-content-new">
            <h2>Join Our Newsletter</h2>
            <p>To get notified about all the blockchain events in Boston (BBA and beyond), sign up for our weekly newsletter.</p>
          </div>
          <div className="who-we-are-new-volunteer-img-hand">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701526498/events/1000_F_361449961_i1QO8dNVVvwzGHdEFI1j7f67GbBWHZXq_1_xequcv.png" />
          </div>         
         </div>  
         <div className="newsletter-container-events">
      <h3 className="subscribe-to-the-newsletter">Subscribe to the Newsletter</h3>
	  <div className="subscribe-to-the-boston-blockchain-association-newsletter-and-get-notified-on-the-latest-blockchain-events-job-openings-opportunities-and-more" >Subscribe to the Boston Blockchain Association newsletter and get notified on the latest blockchain events, job openings, opportunities, and more. </div>
      <div className="newsletter-form-container">
	  <NewsletterGen />
	  </div>
    </div>         
    </div>    
      </Layout>
  )
}